import { useMutation, type MutateResult } from "@vue/apollo-composable"
import { merge } from "lodash"
import { type DataTableFilterMeta } from "primevue/datatable"
import { computed, reactive, ref, watch } from "vue"
import { useRoute } from "vue-router"

import { useApi } from "./apollo/useApi"

import CreateTablePresetMutation from "@/graphql/preset/CreateTablePreset.gql"
import RemoveTablePresetsMutation from "@/graphql/preset/RemoveTablePresets.gql"
import SetDefaultTablePresetMutation from "@/graphql/preset/SetDefaultTablePreset.gql"
import TablePresetListQuery from "@/graphql/preset/TablePresetList.gql"
import UnsetDefaultTablePresetMutation from "@/graphql/preset/UnsetDefaultTablePreset.gql"
import UpdateTablePresetMutation from "@/graphql/preset/UpdateTablePreset.gql"
import {
  type MutationRootCreateTablePresetArgs,
  type MutationRootRemoveTablePresetsArgs,
  type MutationRootSetDefaultPresetArgs,
  type MutationRootUnsetDefaultPresetArgs,
  type MutationRootUpdateTablePresetArgs,
  type QueryRootTablePresetsArgs,
  type Table,
  type TablePreset,
  type TablePresetList,
} from "@/graphql/types"
import { useSessionStore } from "@/store/session"
import { type FilterableColumn, type Preset, type Column } from "@/types"
import { buildNestedObject, generateGQL, objectToGQLString } from "@/utils/gqlbuilder"

export type TablePresetConfiguration<TData, TMappedData = TData> = ReturnType<
  typeof useTablePresetConfiguration<TData, TMappedData>
>

export type TablePresetListResult = { tablePresets: TablePresetList }
export type TablePresetCreateResult = { createTablePreset: TablePreset }
export type TablePresetUpdateResult = { updateTablePreset: TablePreset }
export type TablePresetRemoveResult = { removeTablePresets: number }
export type SetDefaultTablePresetResult = { setDefaultPreset: boolean }
export type UnsetDefaultTablePresetResult = { unsetDefaultPreset: boolean }

export const tablePresetResultMap = {
  getList: (result: TablePresetListResult) => result.tablePresets,
  getCreated: (result: TablePresetCreateResult) => result.createTablePreset,
  getUpdated: (result: TablePresetUpdateResult) => result.updateTablePreset,
  getRemovedCount: (result: TablePresetRemoveResult) => result.removeTablePresets,
  getLinkedCount: undefined,
}

export function useTablePresetConfiguration<TData, TMappedData = TData>(
  table: Table,
  columnDefs: Column<TData, TMappedData>[],
  listRouteName: string
) {
  const route = useRoute()
  const sessionStore = useSessionStore()

  const listQueryVariables = computed(() => ({ table }))

  const listQueryOptions = computed(() => {
    const matchedRouteNames = route.matched.reduce<string[]>(
      (prev, next) => [...prev, <string>next.name],
      []
    )

    return {
      enabled: matchedRouteNames.includes(listRouteName),
    }
  })

  const api = useApi<
    TablePreset,
    "tablePresets",
    TablePresetListResult,
    QueryRootTablePresetsArgs,
    undefined,
    {},
    TablePresetCreateResult,
    MutationRootCreateTablePresetArgs,
    TablePresetUpdateResult,
    MutationRootUpdateTablePresetArgs,
    TablePresetRemoveResult,
    MutationRootRemoveTablePresetsArgs
  >({
    typename: "TablePreset",
    operations: {
      list: TablePresetListQuery,
      getById: undefined,
      create: CreateTablePresetMutation,
      update: UpdateTablePresetMutation,
      remove: RemoveTablePresetsMutation,
      link: undefined,
    },
    resultMap: tablePresetResultMap,
    mapRemovedIds: (variables) => variables.ids,
    listQueryVariables,
    listQueryOptions,
  })

  //Filter
  const filterableColumnDefs: FilterableColumn[] = [] // pass as param

  const filterableColumns = ref<FilterableColumn[]>(filterableColumnDefs)
  const filters = ref<DataTableFilterMeta>()

  const activeFilterableColumns = filterableColumnDefs.filter((col) => col.active ?? true)

  //Filter Preset
  const filterPresetList = ref<Preset<TData, TMappedData>[]>([])
  const selectedFilterPreset = ref<Preset<TData, TMappedData> | undefined>()
  const defaultFilterPreset = ref("")

  //Columns
  const columns = computed(() =>
    columnDefs.filter((c) => !c.requiredPermission || sessionStore.hasRoles([c.requiredPermission]))
  )
  const selectedColumnKeys = ref<Set<string>>(new Set())
  const selectedColumns = computed({
    get: () =>
      [...selectedColumnKeys.value]
        .map((key) => columns.value.find((c) => c.key === key))
        .filter((c) => !!c),

    set: (columns) => {
      selectedColumnKeys.value = new Set(columns.map((c) => c.key))
    },
  })
  const selectedColumnFields = computed(
    () => new Set(selectedColumns.value.flatMap((c) => (c.fields as string[]) ?? [c.key]))
  )

  const activeInitialColumns = columnDefs.filter((col) => col.active ?? true)
  selectedColumns.value = activeInitialColumns

  const initialColumnPresetsLoaded = ref(false)
  api.whenListResultAvailable.then(() => (initialColumnPresetsLoaded.value = true))

  //Columns Preset

  const columnsPresets = computed<TablePreset[]>(() => api.listResult?.tablePresets.items || [])

  const selectedColumnsPresetId = ref<string | undefined>()
  const selectedColumnsPreset = computed({
    get: () => columnsPresets.value.find((p) => p.id === selectedColumnsPresetId.value),
    set: (preset) => (selectedColumnsPresetId.value = preset?.id),
  })

  let initialDefaultSet = false
  watch(
    columnsPresets,
    () => {
      if (
        selectedColumnsPresetId.value &&
        !columnsPresets.value?.find((p) => p.id === selectedColumnsPresetId.value)
      )
        selectedColumnsPresetId.value = undefined

      if ((columnsPresets.value ?? []).length === 0 || initialDefaultSet) return

      const defaultColumnsPreset =
        columnsPresets.value.find((p) => p.isUserDefault) ??
        columnsPresets.value.find((p) => p.isGlobalDefault)

      if (defaultColumnsPreset) selectedColumnsPreset.value = defaultColumnsPreset

      initialDefaultSet = true
    },
    { immediate: true }
  )

  watch(
    selectedColumnsPreset,
    (preset) => {
      selectedColumnKeys.value = new Set(
        preset
          ? preset.columns.flatMap((key) => columnDefs.find((c) => c.key === key)?.key ?? [])
          : activeInitialColumns.map((c) => c.key)
      )
    },
    { immediate: true }
  )

  const { mutate: setDefaultPreset, loading: setDefaultLoading } = useMutation<
    SetDefaultTablePresetResult,
    MutationRootSetDefaultPresetArgs
  >(SetDefaultTablePresetMutation, {
    errorPolicy: "all",
    update: api.prepareListCacheReducer((cachedQuery, _data, variables) => {
      const list = tablePresetResultMap.getList(cachedQuery)
      for (const item of list.items) item.isUserDefault = item.id === variables.id
      return cachedQuery
    }),
  })

  const { mutate: unsetDefaultPreset, loading: unsetDefaultLoading } = useMutation<
    UnsetDefaultTablePresetResult,
    MutationRootUnsetDefaultPresetArgs
  >(UnsetDefaultTablePresetMutation, {
    errorPolicy: "all",
    update: api.prepareListCacheReducer((cachedQuery) => {
      const list = tablePresetResultMap.getList(cachedQuery)
      for (const item of list.items) item.isUserDefault = false
      return cachedQuery
    }),
  })

  api.addRemoveReducer(api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.id)))

  const defaultLoading = computed(() => setDefaultLoading.value || unsetDefaultLoading.value)

  async function create(name: string, columns: string[]): MutateResult<TablePresetCreateResult> {
    const result = await api.create({ name, table, columns })
    selectedColumnsPresetId.value = result?.data?.createTablePreset.id
    return result
  }

  async function setDefault(id: string): MutateResult<SetDefaultTablePresetResult> {
    return await setDefaultPreset({ table, id })
  }

  async function unsetDefault(): MutateResult<UnsetDefaultTablePresetResult> {
    return await unsetDefaultPreset({ table })
  }

  function nameExists(name: string, exclude: TablePreset[] = []) {
    const excludeIds = exclude.map((p) => p.id)
    return columnsPresets.value.some((p) => !excludeIds.includes(p.id) && p.name === name)
  }

  function toggleColumn(column: Column<TData, TMappedData>) {
    if (![...selectedColumnKeys.value].some((key) => key === column.key))
      selectedColumnKeys.value.add(column.key)
    else if (
      [...selectedColumnKeys.value].filter((key) => columns.value.some((c) => c.key === key))
        .length > 1
    )
      selectedColumnKeys.value = new Set(
        [...selectedColumnKeys.value].filter((key) => key !== column.key)
      )
  }

  function setSelectedColumns(columns: Column<TData, TMappedData>[]) {
    selectedColumns.value = columns
  }

  function setSelectedColumnsPreset(preset?: TablePreset) {
    selectedColumnsPreset.value = preset
  }

  function setDefaultFilterPreset(preset: string) {
    // temporary, might be removed after filter preset rework
    defaultFilterPreset.value = preset
  }

  const requestedFields = ref<Set<string>>(new Set())
  const queryFields = ref<string>("")

  watch(selectedColumnKeys, () => updateQueryFields(), { deep: true, immediate: true })

  function updateQueryFields(addFields: string[] = []) {
    if (!initialColumnPresetsLoaded.value) return

    const fields = new Set([...selectedColumnFields.value, ...addFields])

    if (fields.size === 0) return

    const oldFields = buildNestedObject(requestedFields.value, true)
    const newFields = buildNestedObject([...fields].filter((f) => !requestedFields.value.has(f)))

    queryFields.value = objectToGQLString(merge(oldFields, newFields))
    requestedFields.value = fields // TODO: shouldn't this include the old requested fields?
  }

  // resets the query by removing the @client directive
  function resetQuery() {
    const fields = selectedColumnFields.value

    queryFields.value = generateGQL(fields)
    requestedFields.value = fields
  }

  return reactive({
    table,

    filters,
    filterableColumns,
    activeFilterableColumns,
    filterPresetList,
    selectedFilterPreset,
    defaultFilterPreset,

    initialColumnPresetsLoaded,
    activeInitialColumns,
    selectedColumnKeys,
    selectedColumns,
    columns,
    columnsPresets,
    selectedColumnsPresetId,
    selectedColumnsPreset,

    nameExists,
    toggleColumn,
    setSelectedColumns,
    setSelectedColumnsPreset,
    setDefaultFilterPreset,

    api,
    create,
    defaultLoading,
    setDefault,
    unsetDefault,

    queryFields,
    updateQueryFields,
    resetQuery,
  })
}
