/* global appVersion */
import { DefaultApolloClient } from "@vue/apollo-composable"
import { createPinia } from "pinia"
import PrimeVue from "primevue/config"
import { createApp } from "vue"

import App from "./App.vue"
import "./assets/sass/App.scss"
import "./assets/sass/custom/style.scss"
import "./assets/sass/prime/styles.scss"
import installDayjsPlugins from "./dayjs"
import { i18n } from "./i18n"
import registerComponent from "./registerComponents"
import { router } from "./router"
import { installYupMethods } from "./utils/formValidations"
import { apolloClient } from "./vue-apollo"

const app = createApp(App)
const pinia = createPinia()

app.use(PrimeVue, {
  zIndex: {
    modal: 9999,
    overlay: 5000,
    menu: 5000,
    tooltip: 9000,
  },
})
app.use(router)
app.use(pinia)
app.use(i18n)

app.provide(DefaultApolloClient, apolloClient)

registerComponent(app)
installDayjsPlugins()
installYupMethods()

window.appVersion = appVersion

app.mount("#app")
