import { type ProjectCustomer } from "@/graphql/types"
import { i18n } from "@/i18n"
import { type MappedProjectCustomer } from "@/store/customer"
import { type TableConfig } from "@/types"

const config: TableConfig<ProjectCustomer, MappedProjectCustomer> = {
  columnDefs: [
    { key: "n", header: i18n.global.t("admin.kunden.felder.id.label"), size: "id" },

    {
      key: "name",
      header: i18n.global.t("admin.kunden.felder.name.label"),
      size: "medium",
    },
    {
      key: "customerNumber",
      header: i18n.global.t("admin.kunden.felder.kundennummer.label"),
      size: "small",
      active: false,
    },
    {
      key: "address.street",
      header: i18n.global.t("admin.kunden.felder.strasse.label"),
      size: "big",
      active: false,
    },
    {
      key: "address.zipCode",
      header: i18n.global.t("admin.kunden.felder.plz.label"),
      size: "medium",
      active: false,
    },
    {
      key: "address.city",
      header: i18n.global.t("admin.kunden.felder.ort.label"),
      size: "medium",
      active: false,
    },
    {
      key: "address.district",
      header: i18n.global.t("admin.kunden.felder.ortsteil.label"),
      size: "medium",
      active: false,
    },
    {
      key: "address.country",
      format: (customer) =>
        customer.address?.country && i18n.global.t(`countries_code.${customer.address?.country}`),
      header: i18n.global.t("admin.kunden.felder.land.label"),
      size: "medium",
      active: false,
    },
  ],
}

export default config
