import { type ProjectOrganization } from "@/graphql/types"
import { i18n } from "@/i18n"
import { type MappedProjectOrganization } from "@/store/organization"
import { type TableConfig } from "@/types"

const config: TableConfig<ProjectOrganization, MappedProjectOrganization> = {
  columnDefs: [
    { key: "n", header: i18n.global.t("admin.traeger.felder.id.label"), size: "id" },
    {
      key: "name",
      header: i18n.global.t("admin.traeger.felder.name.label"),
      size: "medium",
    },
    {
      key: "address.street",
      header: i18n.global.t("admin.traeger.felder.strasse.label"),
      size: "big",
      active: false,
    },
    {
      key: "address.zipCode",
      header: i18n.global.t("admin.traeger.felder.plz.label"),
      size: "small",
      active: false,
    },
    {
      key: "address.city",
      header: i18n.global.t("admin.traeger.felder.ort.label"),
      size: "medium",
      active: false,
    },
    {
      key: "address.district",
      header: i18n.global.t("admin.traeger.felder.ortsteil.label"),
      size: "medium",
      active: false,
    },
    {
      key: "address.country",
      format: (organization) =>
        organization.address?.country &&
        i18n.global.t(`countries_code.${organization.address?.country}`),
      header: i18n.global.t("admin.traeger.felder.land.label"),
      size: "medium",
      active: false,
    },
    {
      key: "numberOfMembers",
      header: i18n.global.t("admin.traeger.felder.numberOfMembers.label"),
      size: "medium",
      active: false,
    },
  ],
}

export default config
