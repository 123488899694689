export type NestedObject = {
  [key: string]: NestedObject | boolean
}

/**
 * Converts an array of dot-separated strings into a nested object structure.
 *
 * @param {string[]} arr - An array of strings where each string represents a path with dot-separated keys.
 * @returns {NestedObject} - The resulting nested object structure.
 *
 * @example
 * const arr = ["a.b.c", "a.b.d", "e"];
 * const result = buildNestedObject(arr);
 * // Result:
 * // {
 * //   a: {
 * //     b: {
 * //       c: undefined,
 * //       d: undefined
 * //     }
 * //   },
 * //   e: undefined
 * // }
 */
export const buildNestedObject = (arr: Set<string> | string[], client = false): NestedObject => {
  const result: NestedObject = {}

  arr.forEach((str) => {
    const keys = str.split(".")
    let currentLevel = result

    keys.forEach((key, index) => {
      const isKeyClient = client && key !== "id"
      if (!currentLevel[key]) {
        currentLevel[key] = isKeyClient
      }

      if (index === keys.length - 1) {
        currentLevel[key] = isKeyClient
      } else {
        if (typeof currentLevel[key] !== "object") {
          currentLevel[key] = {}
        }
        currentLevel = currentLevel[key] as NestedObject
      }
    })
  })

  return result
}

/**
 * Converts a nested object into a GraphQL-style string representation.
 *
 * @param {NestedObject} obj - The nested object to convert.
 * @returns {string} - The GraphQL-style string representation of the object.
 *
 * @example
 * const obj = { a: { b: { c: undefined } }, d: undefined };
 * const gqlString = objectToGQLString(obj); // "a { b { c } } d"
 */
export function objectToGQLString(obj: NestedObject, parentIsClient = false): string {
  const keys = Object.keys(obj)
  let gqlString = ""

  keys.forEach((key, index) => {
    if (obj[key] && typeof obj[key] === "object") {
      const client = parentIsClient || isClient(obj[key])
      gqlString += `${key}${client ? " @client" : ""} {\n${objectToGQLString(obj[key] as NestedObject, client)}}\n`
    } else {
      gqlString += `${key}${isClient(obj[key]) && !parentIsClient ? " @client" : ""}\n`
    }

    if (index !== keys.length - 1) {
      gqlString += " "
    }
  })

  return gqlString
}

function isClient(obj: NestedObject | boolean): boolean {
  if (typeof obj === "boolean") return !!obj

  const keys = Object.keys(obj)
  return keys.every((key) => isClient(obj[key]))
}

export function generateGQL(arr: Set<string> | string[]) {
  return objectToGQLString(buildNestedObject(arr))
}
