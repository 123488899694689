import { type ProjectInstitution } from "@/graphql/types"
import { i18n } from "@/i18n"
import { type MappedProjectInstitution } from "@/store/institution"
import { type TableConfig } from "@/types"

const config: TableConfig<ProjectInstitution, MappedProjectInstitution> = {
  columnDefs: [
    { key: "n", header: i18n.global.t("labels.id"), size: "id" },
    {
      key: "name",
      header: i18n.global.t("labels.name"),
      size: "medium",
    },
    {
      key: "institutionNumber",
      header: i18n.global.t("admin.einrichtungen.felder.einrichtungsnummer.label"),
      size: "medium",
      active: false,
    },
    {
      key: "internalInstitutionNumber",
      header: i18n.global.t("admin.einrichtungen.felder.interne_einrichtungsnummer.label"),
      size: "medium",
      active: false,
    },
    {
      key: "type",
      format: (institution) =>
        institution.type && i18n.global.t(`institution_type.${institution.type}`),
      header: i18n.global.t("admin.einrichtungen.felder.einrichtungsart.label"),
      size: "medium",
      active: false,
    },
    {
      key: "commune",
      fields: ["commune.name"],
      format: (institution) => institution.commune?.name,
      header: i18n.global.t("labels.sitzgemeinde"),
      size: "medium",
      active: false,
      requiredPermission: "commune:list",
    },
    {
      key: "yearOfConstruction",
      header: i18n.global.t("admin.einrichtungen.felder.baujahr.label"),
      size: "small",
      active: false,
    },
    {
      key: "operatingPermitYear",
      header: i18n.global.t("admin.einrichtungen.felder.betriebserlaubnis.label"),
      size: "medium",
      active: false,
    },
    {
      key: "address.street",
      header: i18n.global.t("labels.address.street"),
      size: "big",
      active: false,
    },
    {
      key: "address.zipCode",
      header: i18n.global.t("labels.address.zip_code"),
      size: "small",
      active: false,
    },
    {
      key: "address.city",
      header: i18n.global.t("labels.address.ort"),
      size: "medium",
      active: false,
    },
    {
      key: "address.district",
      format: (institution) =>
        institution.address?.district && i18n.global.t(`regions.${institution.address?.district}`),
      header: i18n.global.t("labels.address.district"),
      size: "medium",
      active: false,
    },
    {
      key: "address.country",
      format: (institution) =>
        institution.address?.country &&
        i18n.global.t(`countries_code.${institution.address?.country}`),
      header: i18n.global.t("labels.address.country"),
      size: "medium",
      active: false,
    },
  ],
}

export default config
